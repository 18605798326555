import styled from 'styled-components';

export const Content = styled.div`
  > div {
    > p {
      margin-bottom: 60px;
      @media only screen and (max-width: ${(props) => props.theme.breakpoint.md}) {
        margin-bottom: 44px;
      }
    }
  }
`;

export const ContentContainer = styled.div`
  min-height: 600px;
  padding-bottom: 72px;
`;

export const LoadingIconWrapper = styled.div`
  padding-top: 120px;
`;
