import { useRouter } from 'next/router';
import { Heading } from 'legacy-ui';
import { TableMobileItem } from '../../../components/Table';
import { Text } from 'legacy-ui';
import { LanguageKey } from '../../../types/common';
import { getPrice } from '../../../utils/property';
import { getApplicationLink } from '../../../utils/spa-integration';
import StatusSelect from './StatusSelect';
import { ApplicationRowProps } from './types';
import { useWhiteLabel } from '../../../providers/WhiteLabelProvider';

const ApplicationsRow = ({ application, generalTexts, applicationTexts, isMobile }: ApplicationRowProps) => {
  const router = useRouter();
  const { locale } = router;
  const whiteLabelConfig = useWhiteLabel();

  const applicationLink =
    application.property.settings.applyMethod === 'DEFAULT'
      ? getApplicationLink(application.id, locale as LanguageKey, whiteLabelConfig.urls.spa)
      : `application/${application.id}`;

  const roomsText = `${application.property.propertyDetails.rooms} ${(application.property.propertyDetails.rooms === 1
    ? generalTexts.PropertyDetails.RoomSingular
    : generalTexts.PropertyDetails.RoomPlural
  ).toLowerCase()}`;
  const sizeText = `${application.property.propertyDetails.size}m²`;
  const priceText = getPrice(application.property, generalTexts.Misc.PerMonth);

  if (isMobile) {
    return (
      <tr key={application.id} onClick={() => router.push(applicationLink)}>
        <td>
          <TableMobileItem>
            <Heading tag="h3">{application.property.name}</Heading>
            <ul>
              <li>{sizeText}</li>
              <li>{roomsText}</li>
              <li>{priceText}</li>
            </ul>
            <div>
              <StatusSelect applicationTexts={applicationTexts} application={application} />
            </div>
          </TableMobileItem>
        </td>
      </tr>
    );
  }
  return (
    <tr key={application.id} onClick={() => router.push(applicationLink)}>
      <td>
        <Text weight="sb">{application.property.name}</Text>
      </td>
      <td>{application?.propertyGroup?.name || '-'}</td>
      <td>{sizeText}</td>
      <td>{roomsText}</td>
      <td>{priceText}</td>
      <td>
        <StatusSelect applicationTexts={applicationTexts} application={application} />
      </td>
    </tr>
  );
};

export default ApplicationsRow;
