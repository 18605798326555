import { ApplicationCMSResult } from '../../../types/cms/applicationTypes';

interface ApplicationTableHeaderProps {
  applicationTexts: ApplicationCMSResult;
}

const ApplicationsTableHeader = ({ applicationTexts }: ApplicationTableHeaderProps) => {
  return (
    <thead>
      <tr>
        <th>{applicationTexts.TableHeaderProperty}</th>
        <th>{applicationTexts.TableHeaderPropertyGroup}</th>
        <th>{applicationTexts.TableHeaderSize}</th>
        <th>{applicationTexts.TableHeaderRooms}</th>
        <th>{applicationTexts.TableHeaderPrice}</th>
        <th>{applicationTexts.TableHeaderStatus}</th>
      </tr>
    </thead>
  );
};

export default ApplicationsTableHeader;
