import React, { ReactNode } from 'react';
import styled from 'styled-components';

const TableContainer = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  margin-bottom: 32px;
  tr {
    border-bottom: 1px solid ${(props) => props.theme.color.propertyTableBorderColor};
  }
  thead {
    th {
      font-weight: 600;
      padding: 0 8px 20px 8px;
      color: ${(props) => props.theme.color.propertyTableHeaderTextColor};
    }
  }
  tbody {
    tr {
      cursor: pointer;
      &:hover {
        background: ${(props) => props.theme.color.propertyTableRowBackgroundColorHover};
      }
      &:active {
        background: ${(props) => props.theme.color.propertyTableRowBackgroundColorActive};
      }
    }
    td {
      padding: 20px 8px;
      strong {
        font-weight: 600;
      }
      svg {
        margin-right: 8px;
      }
    }
  }
`;

export const TableMobileItemContainer = styled.div`
  h3 {
    margin-bottom: 8px;
  }
  ul {
    display: flex;
    margin-bottom: 12px;
    li {
      margin-right: 20px;
    }
  }
`;

interface TableProps {
  children: ReactNode;
}

export const Table = ({ children }: TableProps) => {
  return <TableContainer>{children}</TableContainer>;
};

export const TableMobileItem = ({ children }: TableProps) => {
  return <TableMobileItemContainer>{children}</TableMobileItemContainer>;
};
