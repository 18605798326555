import { faHouseChimneyUser } from '@fortawesome/pro-light-svg-icons';
import { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';
import { Heading, styled } from 'legacy-ui';
import LoadingIcon from '../../components/LoadingIcon';
import NoListMessage from '../../components/NoListMessage';
import SEO from '../../components/SEO';
import ApplicationsTable from '../../sections/applications/ApplicationsTable';
import Container from '../../sections/common/Container';
import { Content, ContentContainer, LoadingIconWrapper } from '../../sections/common/Content';
import Page from '../../sections/common/Page';
import PageHero from '../../sections/common/PageHero';
import { ApplicationCMSResult } from '../../types/cms/applicationTypes';
import { Localized } from '../../types/cms/generalTypes';
import { LanguageKey } from '../../types/common';
import { ServerData } from '../../types/serverData';
import { loadAllDataFromCms } from '../../utils/http';
import { createLanguagePaths } from '../../utils/languages';
import { useWhiteLabel } from '../../providers/WhiteLabelProvider';
import useApplications from 'hooks/useApplications';
import useRedirectUser from 'hooks/useRedirectUser';
import { useAtomValue } from 'jotai';
import { userState } from 'state/userState';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const AnonymizedApplications = styled.div`
  background-color: ${(props) => props.theme.color.secondaryBackgroundColorHover};
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    border-radius: 8px;
  }
`;

const Applications = ({ headerData, footerData, generalData, pageData, languagePaths }: ServerData<ApplicationCMSResult>) => {
  const { t } = useTranslation('applications');
  const router = useRouter();
  const { companyIds } = useWhiteLabel();
  const { user, status } = useAtomValue(userState);
  const applications = useApplications(user?.id, companyIds);
  const isUserLoaded = status === 'loaded';
  useRedirectUser(user, isUserLoaded, router);
  const loading = !isUserLoaded || !applications;

  if (!applications || !applications.length) {
    return (
      <Page header={{ data: headerData }} footer={{ data: footerData, languagePaths }}>
        <SEO
          title={pageData.SEO.Title}
          description={pageData.SEO.Description}
          images={[]}
          noIndex={pageData.SEO.NoIndex}
          languagePaths={languagePaths}
        />
        <Container>
          <PageHero smallerHeight />
          <ContentContainer>
            {loading ? (
              <LoadingIconWrapper>
                <LoadingIcon />
              </LoadingIconWrapper>
            ) : (
              <NoListMessage
                icon={faHouseChimneyUser}
                headingText={pageData.NoApplicationsHeadline}
                infoText={pageData.NoApplicationsDescription}
                buttonText={pageData.SearchProperties}
              />
            )}
          </ContentContainer>
        </Container>
      </Page>
    );
  }

  return (
    <Page header={{ data: headerData }} footer={{ data: footerData, languagePaths }}>
      <SEO
        title={pageData.SEO.Title}
        description={pageData.SEO.Description}
        images={[]}
        noIndex={pageData.SEO.NoIndex}
        languagePaths={languagePaths}
      />
      <Container>
        <PageHero smallerHeight />
        <ContentContainer>
          <Content>
            <div>
              <Heading size="r">{pageData.ApplicationsHeadline}</Heading>
            </div>
            <ApplicationsTable applications={applications} generalTexts={generalData} applicationTexts={pageData} />
          </Content>
          {!!user?.anonymizedApplicationsCount && (
            <AnonymizedApplications>{t('anonymizedApplicationsCount', { count: user?.anonymizedApplicationsCount })}</AnonymizedApplications>
          )}
        </ContentContainer>
      </Container>
    </Page>
  );
};

export default Applications;

export async function getServerSideProps({ locale }: GetServerSidePropsContext) {
  try {
    const dataPromise = loadAllDataFromCms<Localized<ApplicationCMSResult>>('application', locale as LanguageKey, ['SEO']);

    const translationsPromise = serverSideTranslations(locale as LanguageKey);

    const [data, translations] = await Promise.all([dataPromise, translationsPromise]);

    const languagePaths = createLanguagePaths(data.pageData, locale as LanguageKey);

    return {
      props: { ...data, languagePaths, ...translations },
    };
  } catch (error) {
    return {
      notFound: true,
    };
  }
}
