import { useEffect, useState } from 'react';
import { Application } from 'sections/applications/ApplicationsTable/types';
import { getFromApi } from 'utils/http';

const useApplications = (userId: string | undefined, companyIds?: string[]): Application[] | undefined => {
  const [applications, setApplications] = useState<Application[]>();

  useEffect(() => {
    (async () => {
      if (!userId) return;
      const companyIdsQueryParam = companyIds?.length ? `?companyIds=${companyIds.join(',')}` : '';
      const fetchedApplications = await getFromApi(`applications/${userId}${companyIdsQueryParam}`);
      setApplications(fetchedApplications);
    })();
  }, [userId, companyIds]);

  return applications;
};

export default useApplications;
