import { Table } from '../../../components/Table';
import { useGetWindowSize } from '../../../hooks/useGetWindowSize';
import { isBelowBreakpoint } from '../../../styles/theme';
import ApplicationsRow from './ApplicationsRow';
import ApplicationsTableHeader from './ApplicationsTableHeader';
import { ApplicationsTableProps } from './types';

const ApplicationsTable = ({ applications, generalTexts, applicationTexts }: ApplicationsTableProps) => {
  const windowSize = useGetWindowSize();
  const isMobile = isBelowBreakpoint(windowSize?.width || 0, 'xl');
  return (
    <Table>
      {!isMobile && <ApplicationsTableHeader applicationTexts={applicationTexts} />}
      <tbody>
        {applications.map((application) => (
          <ApplicationsRow
            key={application.id}
            application={application}
            generalTexts={generalTexts}
            applicationTexts={applicationTexts}
            isMobile={isMobile}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default ApplicationsTable;
