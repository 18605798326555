import { NextRouter } from 'next/router';
import { useEffect } from 'react';
import { UserAPIResult } from 'types/api/userTypes';
import { QueryParams } from 'utils/constants';

const useRedirectUser = (user: UserAPIResult | undefined, isUserLoaded: boolean, router: NextRouter) => {
  useEffect(() => {
    if (!isUserLoaded) {
      return;
    }
    if (!user) {
      router.push(`/${router.locale}?${QueryParams.AuthLogin}`);
    }
  }, [user, isUserLoaded, router]);
};

export default useRedirectUser;
