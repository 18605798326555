import {
  APPLICATION_STATUS_ACTIVE,
  APPLICATION_STATUS_CANCELLED,
  APPLICATION_STATUS_DONE,
  APPLICATION_STATUS_EMAIL_ONLY,
  APPLICATION_STATUS_OFFERED,
  APPLICATION_STATUS_REJECTED,
  APPLICATION_STATUS_TERMINATED,
  PROPERTY_FOR_SALE,
} from '../../../utils/constants';
import { Pill } from 'legacy-ui';
import { StatusSelectProps } from './types';

const StatusSelect = ({ applicationTexts, application }: StatusSelectProps) => {
  return (
    <>
      {application.status === APPLICATION_STATUS_ACTIVE && <Pill color="green">{applicationTexts.StatusActive}</Pill>}
      {application.status === APPLICATION_STATUS_OFFERED && <Pill color="orange">{applicationTexts.StatusOffered}</Pill>}
      {application.status === APPLICATION_STATUS_DONE && (
        <Pill color="red">
          {application.property.transactionDetails.type === PROPERTY_FOR_SALE ? applicationTexts.StatusSold : applicationTexts.StatusRented}
        </Pill>
      )}
      {application.status === APPLICATION_STATUS_REJECTED && <Pill color="red">{applicationTexts.StatusRejected}</Pill>}
      {application.status === APPLICATION_STATUS_CANCELLED && <Pill color="red">{applicationTexts.StatusCancelled}</Pill>}
      {application.status === APPLICATION_STATUS_EMAIL_ONLY && <Pill>{applicationTexts.StatusExternal}</Pill>}
      {application.status === APPLICATION_STATUS_TERMINATED && <Pill color="red">{applicationTexts.StatusTerminated}</Pill>}
    </>
  );
};

export default StatusSelect;
